import "../assets/styles/page";

import "bootstrap/js/dist/index";
import "bootstrap/js/dist/collapse";

import Rails from "@rails/ujs";
Rails.start();

require.context("../assets/images", true);
require.context("../assets/images/page", true);

window.jQuery = $;

$(function () {
  // Sticky header
  $(window).scroll(function () {
    const winTop = $(window).scrollTop();
    if (winTop >= 30) {
      $("nav").addClass("sticky");
    } else {
      $("nav").removeClass("sticky");
    }
  });

  /**
   * activates newsletter subscription button if
   * conditions checkbox is checked
   */
  $("#newsletter_conditions").on("change", (event) => {
    const button = jQuery("#newsletter-action");
    const checkbox = jQuery("#newsletter_conditions");
    if (checkbox.is(":checked")) {
      button.removeClass("disabled");
    } else {
      button.addClass("disabled");
    }
  });
});

/**
 * Animates scrollTo specific element on Page
 * @param element
 */
window.animateScrollTo = (element, speed = "slow", offset = 150) => {
  jQuery("html, body").animate(
    { scrollTop: jQuery(element).offset().top - offset },
    speed
  );
};

/**
 * registers Eventlisteners to ShopFormSubmission Form
 */
window.registerFormEvents = () => {
  const shopForm = jQuery("#register-shop");
  const shopTypeField = jQuery("#shop_form_submission_shoptype");
  let currentShopType = shopTypeField.val();

  shopForm.on("ajax:beforeSend", () => {
    animateScrollTo("#register-shop");
  });

  jQuery("#register-shop").on("shown.bs.collapse", (event) => {
    animateScrollTo("#register-shop");
  });

  if (currentShopType == "online" || currentShopType == "hybrid") {
    jQuery("#register-shop-online").addClass("show");
  }
  if (currentShopType == "offline" || currentShopType == "hybrid") {
    jQuery("#register-shop-offline").addClass("show");
  }

  jQuery("#register-shop-online").on("shown.bs.collapse", function (event) {
    currentShopType = currentShopType == "offline" ? "hybrid" : "online";
    shopTypeField.val(currentShopType);
    jQuery("#online-shop-button").addClass("active");
    event.stopPropagation();
  });

  jQuery("#register-shop-online").on("hidden.bs.collapse", (event) => {
    currentShopType = currentShopType == "hybrid" ? "offline" : "";
    shopTypeField.val(currentShopType);
    jQuery("#online-shop-button").removeClass("active");
    event.stopPropagation();
  });

  jQuery("#register-shop-offline").on("shown.bs.collapse", function (event) {
    currentShopType = currentShopType == "online" ? "hybrid" : "offline";
    shopTypeField.val(currentShopType);
    jQuery("#offline-shop-button").addClass("active");
    event.stopPropagation();
  });
  jQuery("#register-shop-offline").on("hidden.bs.collapse", (event) => {
    currentShopType = currentShopType == "hybrid" ? "online" : "";
    shopTypeField.val(currentShopType);
    jQuery("#offline-shop-button").removeClass("active");
    event.stopPropagation();
  });

  jQuery("#image-upload").on("change", function () {
    updateImagePreview(this);
    jQuery("#uploaded-image-preview-area").addClass("d-block");
    jQuery("#image-upload-field").addClass("d-none");
  });
  jQuery("#remove-image").on("click", (e) => {
    jQuery("#uploaded-image-preview-area").removeClass("d-block");
    jQuery("#image-upload-field").removeClass("d-none");
    jQuery("#image-upload").val("");
    e.preventDefault();
  });
};

window.updateImagePreview = (input) => {
  if (input.files && input.files[0]) {
    var reader = new FileReader();
    reader.onload = function (e) {
      jQuery("#image-upload-preview").attr("src", e.target.result);
    };
    reader.readAsDataURL(input.files[0]);
  }
};
